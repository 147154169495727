<template>
  <v-btn
    v-if="url"
    v-bind="$attrs"
    v-on="$listeners"
    depressed
    color="grey lighten-3"
    rounded
    small
    :loading="loading"
    @click.prevent.stop="onDownloadFile"
  >
    <v-icon size="medium" left>mdi-download</v-icon>
    {{ $t("buttons.download") }}
  </v-btn>
</template>

<script>
  /**
   * @project idenfit
   * @developer Halil Kılıçarslan
   * @description FileDownloadButton Component Logic
   * @date 17.07.2020
   */
  export default {
    inheritAttrs: false,
    name: "FileDownloadButton",
    props: {
      url: {
        type: String,
        required: false
      }
    },
    data: () => ({
      loading: false
    }),
    methods: {
      onDownloadFile() {
        if (this.url) {
          this.loading = true;
          this.$api.storageService
            .download(this.url)
            .then(({ data, errors }) => {
              if (!data.error && !errors) {
                const extension = this.url
                  .split(".")
                  .pop()
                  .split(/\#|\?/g)[0];
                const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `report_${this.$moment().format("DD-MM-YYYY-HH-mm-ss")}.${extension}`);
                document.body.appendChild(link);
                link.click();
              }
            })
            .catch(e => this.$helpers.showNotification(e.message))
            .finally(() => (this.loading = false));
        }
      }
    }
  };
</script>

<style scoped></style>
